import {getStaticPaths as GSPaths, getStaticProps as GSProps} from '../../default';
import DynamicPage from '../../default';

export default function Page(props) {
  return <DynamicPage {...props} />;
}

export async function getStaticPaths() {
  return await GSPaths('ro');
}

export async function getStaticProps(context) {
  return await GSProps({
    ...context,
    locale: 'ro',
    htmlLang: 'ro',
    direction: 'ltr',
  });
}
